
.dictionary{
  width: 100%;

  @include min-small {
    display: flex;
    flex-wrap: wrap;
  }
}

.dictionary__key {
  box-sizing: border-box;
  padding-bottom:16px;
  @include min-small {
    width:33.333%;
  }

  &.bordered {
    border-bottom:1px solid $color-grey-light;
  }
}


.dictionary__head.equal {
  @include min-small {
    width:52%;
    padding-right:4%;
    margin-left:0;
  }
}
.dictionary__content.equal{
  @include min-small {
    width:48%;
    margin-left:0;
    padding-right:0%;
  }
}


.dictionary__value {
  padding-bottom:16px;
  @include min-small {
    width:62.666%;
    margin-left:4%;
  }

  &.bordered {
    border-bottom:1px solid $color-grey-light;
  }
}

.dictionary__key--wide {
  width:75%;
  overflow: hidden;
}

.dictionary__delimiter {
  margin-top:10px;
  margin-bottom:60px;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $color-grey-lightest;
}
