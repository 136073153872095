.tablesorter {

  .tablesorter-header {
    padding-right: 24px;
    position: relative;
    cursor:pointer;

    &.tablesorter-headerUnSorted{
      &:after {
        content:'↑';
        position: absolute;
        top:5px;
        right:8px;
        color:#666;
        opacity:0.1;
        transition: opacity 0.25s;
      }

      &:hover {
        &:after {
          opacity:1;
        }
      }

    }

    &.tablesorter-headerAsc{
      &:after {
        content:'↑';
        position: absolute;
        top:5px;
        right:8px;
        color:#666;
      }
    }

    &.tablesorter-headerDesc{
      &:after {
        content:'↓';
        position: absolute;
        top:5px;
        right:8px;
        color:#666;
      }
    }
  }



}
