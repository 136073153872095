@font-face {
  font-family: 'DejaVuSansCondensed';
  src: url('../fonts/DejaVuSansCondensed-webfont.eot');
  src: url('../fonts/DejaVuSansCondensed-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/DejaVuSansCondensed-webfont.woff') format('woff'),
  url('../fonts/DejaVuSansCondensed-webfont.ttf') format('truetype'),
  url('../fonts/DejaVuSansCondensed-webfont.svg#DejaVuSansCondensed') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DejaVuSansCondensedBold';
  src: url('../fonts/DejaVuSansCondensed-Bold-webfont.eot');
  src: url('../fonts/DejaVuSansCondensed-Bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/DejaVuSansCondensed-Bold-webfont.woff') format('woff'),
  url('../fonts/DejaVuSansCondensed-Bold-webfont.ttf') format('truetype'),
  url('../fonts/DejaVuSansCondensed-Bold-webfont.svg#DejaVuSansCondensedBold') format('svg');
  font-weight: normal;
  font-style: normal;
}
