.page-cover {
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  z-index: 20;
  width: 100vw;
  height: 100vh;
  background-color: rgba(white, 0.95);
  transition: 0.5s;
  place-items: center;

  &.is-hidden {
    top: -50vw;
    opacity: 0;
    transform: scale(0.05);
  }

  .content-container {
    position: fixed;
    //position: relative;
    top:2rem;
    right: 2rem;
    bottom:4rem;
    left: 2rem;
    max-height: calc(100vh - 4rem);
    padding: 2rem;
    background-color: white;
    border: 1px solid #E30045;
    overflow:hidden;
    overflow-y: auto;

    @include min-small {
      position: relative;
      top:auto;
      right: auto;
      bottom: auto;
      left:auto;
      margin-top: 2.1rem;
      margin-bottom: 2rem;
    }
  }

  .content__close {
    position: fixed;
    top: 1rem;
    right: 1rem;
    padding:0.25em 0.75em;
    background-color: #E30045;
    border:0;
    border-radius: 999px;
    appearance: none;
    color:white;

    @include min-small {
      position: absolute;


    }


  }

}
