@mixin ultrasmall {
  @media (max-width: #{$breakpoint-xs - 1px}) {
    @content;
  }
}

@mixin ultrasmall-portrait {
  @media (max-width: #{$breakpoint-xs - 1px}) and (orientation: portrait)  {
    @content;
  }
}

@mixin extrasmall {
  @media (min-width: #{$breakpoint-xs}) and (max-width: #{$breakpoint-sm - 1px}) {
    @content;
  }
}

@mixin min-extrasmall {
  @media (min-width: #{$breakpoint-xs}) {
    @content;
  }
}

@mixin max-extrasmall {
  @media (max-width: #{$breakpoint-sm - 1px}) {
    @content;
  }
}

@mixin max-extrasmall-landscape {
  @media (max-width: #{$breakpoint-sm - 1px}) and (orientation: landscape) {
    @content;
  }
}

@mixin max-extrasmall-portrait {
  @media (max-width: #{$breakpoint-sm - 1px}) and (orientation: portrait) {
    @content;
  }
}




@mixin small {
  @media (min-width: #{$breakpoint-sm}) and (max-width: #{$breakpoint-md - 1px}) {
    @content;
  }
}

// defines rule for 'small' and up
@mixin min-small {
  @media (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin medium {
  @media (min-width: #{$breakpoint-md}) and (max-width: #{$breakpoint-lg - 1px}) {
    @content;
  }
}

@mixin min-medium {
  @media (min-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin large {
  @media (min-width: #{$breakpoint-lg}) {
    @content;
  }
}

/* typo functions */
@mixin set_standard_font($size:$font-size-standard) {
  font-family: $font-fira;
  font-weight: $font-fira-weight-light;
  font-size: $size;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// css3-calc for all browsers
@mixin calc($property, $expression) {
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: -moz-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

// set background-image
@mixin bg-image($source){
  background-image: image_url($source);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}


