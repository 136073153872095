.c-header {
  margin-bottom: 1.5rem;
  padding: 1rem 0 1rem 0;
  height: auto;
  background: url('../images/header/background.jpg') left center no-repeat;
  background-size: cover;
  background-color: #2992CB;

  @include min-small {
    margin-bottom: 2rem;
    padding: 2rem 0 2rem 0;
    background-size: cover;
    background-position-y: 40%;
  }

  &__container {
    display: flex;
    justify-content: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 80px;
    @include min-small {
      height: 260px;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  &__logo {
    height: 100%;
    width: 50%;
    padding-top: 1rem;
    text-align: right;

    @include min-small {
      width: 50%;
    }

    img {
      height: 100%;
      max-width: 120px;

      @include min-small {
        max-width: none;
        width: 160px;
        max-width: 160px;
      }
    }
  }

  &__slogan {
    height: 100%;
    width: 50%;
    text-align: right;

    @include min-small {
      text-align: right;
      width: 100%;
    }

    img {
      height: 100%;
      max-width: 140px;

      @include min-small {
        max-width: none;
      }
    }
  }
}
