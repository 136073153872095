.pager {

  transition: opacity 0.5s;
  &.is_hidden {
    opacity: 0.2;
  }
  .btn {
    padding:4px 8px;
    @include min-small{
      padding:10px 20px;
    }

  }

  .prev{

  }

  .last{

  }

  li > .pagedisplay{
    display: inline-block;
    float:none;
  }

  .pagesize.form-control,
  .gotoPage.form-control{
    display:inline-block;
    width:auto;
  }

  li > .pagesize-hint{
    float:none;
  }

  .pagesize.form-control{
    display: inline-block;
    position: static;
  }
}
