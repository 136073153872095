.detail-table {
  width: 100%;
  table-layout: fixed;
  @include min-small {
    table-layout: auto;
    width: 66.666%;

  }

  tr {
    border-bottom: 1px solid #ddd;
    vertical-align: top;
  }

  th {
    vertical-align: top;
    width: 45%;
    min-width: 180px;
    @include min-small {
      width: 50%;
      min-width: 0px;
    }

  }
  td, th {
    padding: 8px 0;
  }

}

tr.detail-table__tr--no-line {
  border-bottom: 1px solid transparent;
}
