.c-filters {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 48px;
  flex-direction: column;
  transition: opacity 0.5s;

  @include min-small {
    flex-direction: row;
  }

  &.is_hidden {
    opacity: 0.1;
  }
  &__column {
    width: 100%;
    @include min-small {
      width: 66.666%;
      padding: 8px 8px;
      padding-right: 2%;
    }

    &.right {
      width: 100%;
      @include min-small {
        padding-left: 2%;

        border-left: 1px solid silver;
        width: 33.333%;
      }

      &.no-separator {
        border-left: 1px solid transparent;
      }
    }
  }

  &__container {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 16px;
    flex-direction: column;
    @include min-small {
      flex-direction: row;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__select {

    background-color: white;
    width: 100%;
    padding: 4px;
    @include min-small {
      width: 33.333%;
      padding: 2px;
    }

    &.is_disabled {
      color: #999;
      background-color: #dedede;
    }

    &.group-step0 {
      width: 100%;
    }
    &.hidden {
      visibility: hidden;
    }
  }

  &__textfield {
    width: 100%;
    &.city_filter {
      margin-bottom: 24px;
      @include min-small {
        margin-bottom: 0px;
      }
    }

  }
}

.time-buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 24px;
  @include min-small {
    margin-bottom: 0px;
    justify-content: space-between;
  }

  .btn {
    display: inline-block;
  }
}
