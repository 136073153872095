// Normalize Styles
@import 'node_modules/normalize.css/normalize';
@import 'vendor/bootstrap.min';
@import 'vendor/ng_responsive_tables';

@import 'settings/breakpoints';
@import 'settings/colors';
@import 'settings/fonts';
@import 'settings/page';

@import 'tools/mixins';

@import 'generic/fonts';

@import 'elements/elements';

@import 'layout/dictionary';

@import 'components/container';
@import 'components/detail-table';
@import 'components/filters';
@import 'components/header';
@import 'components/page-cover';
@import 'components/preloader';
@import 'components/result';
@import 'components/tablesorter';

@import 'utils/color';
@import 'utils/margins';
@import 'utils/text-truncater';

@import 'legacy/footer';
@import 'legacy/pager';
@import 'legacy/bootstrap-custom';

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

