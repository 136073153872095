html{
  min-height: 100%;
  overflow-y: scroll;

  &.is-fixed {
    overflow: hidden;
  }
}

body {
  font-family: $font-secondary-normal;
  font-size:14px;

  &.is-fixed {
    overflow: hidden;
    padding-right: 15px;
  }
}

* {
  box-sizing: content-box;
}

h2 {
  font-size: 28px;
  margin-bottom: 28px;
  font-family:  $font-primary-bold;

  @include min-small{
    font-size: 32px;
    margin-bottom: 32px;
  }
}

h3 {
  font-size:28px;
  font-family:  $font-primary-normal;
}
