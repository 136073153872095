.c-preloader{
  position: relative;
  &__title{
    font-size:24px;
    text-align: center;
    position: absolute;
    width: 100%;
    z-index:-2;
  }

  &__stretcher{
    width: 100%;
    padding-bottom:40%;
  }

  @keyframes lds-dual-ring {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes lds-dual-ring {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .lds-css.ng-scope{
    margin-top:24px;
    position: absolute;
    width: 100%;
    height: 100%;

  }

  .lds-dual-ring {
    position: relative;
    left:50%;
    //transform: translateX(-50%);

  }
  .lds-dual-ring div {

    position: absolute;
    width: 80px;
    height: 80px;
    top: 20px;
    left: 20px;
    border-radius: 50%;
    border: 4px solid #000;
    border-color: #bd4030 transparent #bd4030 transparent;
    -webkit-animation: lds-dual-ring 1s linear infinite;
    animation: lds-dual-ring 1s linear infinite;
  }
  .lds-dual-ring {
    width: 200px !important;
    height: 200px !important;
    -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
    transform: translate(-100px, -100px) scale(1) translate(100px, 100px) translateX(-25%);
  }
}
