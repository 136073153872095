.pagination {
  &--flexed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include min-small {
      width: 50%;
      margin-left:25%;

    }
  }
}
