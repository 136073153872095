$error_red : #E30045;
$verdi-blue:  #3368B7;
$verdi-bright-green : #89B544;


$color-primary: #c00202;
$color-primary-negative: #fff;

$color-secondary: #b2b2b2;
$color-secondary-light: #f1f3f5;
$color-secondary-negative: #ffffff;
$color-secondary-highlight: $color-primary;

$color-awp-blue: #334F6E;
$color-tertiary-negative: #ffffff;

$color-orange-light:#fdf2d5;

$color-grey-lightest: #f9fafa;
$color-grey-light: #ddd;
$color-grey-light2: #D9D9D9;

$color-grey: #888;
$color-grey-darker: #4b4b4b;
$color-grey-dark: #333;
$color-grey-darkest: #111;
$color-blue: #247bbd;
$color-green: #3b0;
$color-orange: #f57900;
