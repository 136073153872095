.footer {
  min-height: 200px;
  padding: 40px 0;
  color: white;
  background: #393335 url(../images/background/bg_footer.png) no-repeat scroll center 0;
  width:100%;
}

.footer + .skip_top {
  height: auto;
  margin: 0;
  border-bottom-width: 7px; }

.footer + .skip_top .wrapper {
  width: 102px;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase; }

.footer + .skip_top .ico_plus {
  position: relative;
  top: 9px;
  font-size: 20px; }

.footer .container {
  position: relative; }

.footer .col-sm-4 {
  border-left: 1px solid #bebcb9;
  padding-left: 20px; }

.footer .col-sm-4:first-child {
  border: 0 none; }

.footer .title {
  margin: 0 0 15px;
  font-size: 18px;
  text-transform: uppercase; }

.footer .list-group {
  margin: 0;
  padding: 0;
  list-style: none; }

.footer .list-group a {
  color: white; }

.footer .logo {
  position: absolute;
  top: 20px;
  right: -75px;
  width: 82px;
  height: 82px;
  text-indent: -9999px;
  background: transparent url(../images/logo/logo_verdi_grau_45.png) no-repeat scroll 0 0
}

.footer .logo-wrapper .logo {
  right: 30px;
  top: -0px;
}

