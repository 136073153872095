.result {
  display: none;

  &__table {

    &.table-bordered {
      @include max-extrasmall {
        border: 0;
      }

    }

    tbody {
      font-size: 16px;
      hyphens: auto;
    }

    .group {
      width: 14%;
    }
    .date {
      width: 8%;
    }
    .time {
      width: 12%;
    }
  }

}
